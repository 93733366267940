
import React, { useState } from 'react'
import Layout from '../../../components/layout'
import styled, { keyframes } from 'styled-components'
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Grid } from '@material-ui/core'
import { Colors } from '../../../constants'

const PrivacyImg = require('../../../dist/images/privacy.svg');

export default () => {
    return ( 
        <Layout padTop={true} title={'Privacy Policy'}>
            <PolicyWrapper>
                <Container>
                <PolicySection spacing={3} container style={{marginBottom: '50px', borderBottom: `1px solid ${Colors.auxidusBlue}`}}>
                    <Grid item xs={12} sm={8}>
                        <HeaderText>
                            Privacy Policy
                        </HeaderText>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <HeaderImage />
                    </Grid>
                </PolicySection>
                    <PolicySection spacing={3} container>
                        <Grid item xs={12} sm={4}>
                            <SectionTitle variant={'h5'}>
                                Preface
                            </SectionTitle>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <SectionBody  >
                                Auxidus Technologies LLC. operates the www.auxidus.com website, which provides information regarding our services. <br/><br/>
                                This page is used to inform website visitors regarding our policies with the collection, use, and disclosure of Personal Information on the Auxidus website.<br />
                                If you choose to use our site, then you agree to the collection and use of information in relation with this policy. 
                                The Personal Information that we collect are used for providing and improving user experience whilst using the Auxidus website. 
                                We will not use or share your information with anyone except as described in this Privacy Policy. <br /><br/>
                                The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at www.auxidus.com, unless otherwise defined in this Privacy Policy.
                            </SectionBody>
                            <Grid item xs={12} style={{borderBottom: `1px solid ${Colors.auxidusBlue}`, width: '100%', paddingTop: '10px'}}/>
                        </Grid>
                    </PolicySection>

                    <PolicySection spacing={3} container>
                        <Grid item xs={12} sm={4}>
                            <SectionTitle variant={'h5'}>
                                Information Collection and Use
                            </SectionTitle>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <SectionBody  >
                            For a better experience while using our website, we may require you to provide us with certain personally identifiable information, including but not limited to your name, phone number, and email address. The information that we collect will be used to contact or identify you.
                            </SectionBody>
                            <Grid item xs={12} style={{borderBottom: `1px solid ${Colors.auxidusBlue}`, width: '100%', paddingTop: '10px'}}/>
                        </Grid>
                    </PolicySection>
                    <PolicySection spacing={3} container>
                        <Grid item xs={12} sm={4}>
                            <SectionTitle variant={'h5'}>
                                Data Logging
                            </SectionTitle>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <SectionBody  >
                            We want to inform you that whenever you visit our website, we collect information that your browser sends to us that is called Log Data. This Log Data may include information such as your computer’s Internet Protocol ("IP") address, browser version, pages of our website that you visit, the time and date of your visit, the time spent on those pages, and other statistics.
                            </SectionBody>
                            <Grid item xs={12} style={{borderBottom: `1px solid ${Colors.auxidusBlue}`, width: '100%', paddingTop: '10px'}}/>
                        </Grid>
                    </PolicySection>

                    <PolicySection spacing={3} container>
                        <Grid item xs={12} sm={4}>
                            <SectionTitle variant={'h5'}>
                                Cookies
                            </SectionTitle>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <SectionBody  >
                            Cookies are files with small amount of data that is commonly used an anonymous unique identifier. 
                            These are sent to your browser from the website that you visit and are stored on your computer’s hard drive.<br/><br/>
                            Our website uses these "cookies" to collection information and to improve your user experience. You have the option to either accept or refuse these cookies, 
                            and know when a cookie is being sent to your computer. If you choose to refuse our cookies, you may not be able to use some portions of our website.<br/><br/>
                            For more general information on cookies, please read "What Are Cookies".
                            </SectionBody>
                            <Grid item xs={12} style={{borderBottom: `1px solid ${Colors.auxidusBlue}`, width: '100%', paddingTop: '10px'}}/>
                        </Grid>
                    </PolicySection>

                    <PolicySection spacing={3} container>
                        <Grid item xs={12} sm={4}>
                            <SectionTitle variant={'h5'}>
                                Service Providers
                            </SectionTitle>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <SectionBody  >
                                We may employ third-party companies and individuals due to the following reasons:<br/><br/>
                                <ul  style={{paddingLeft: '18px'}}>
                                    <li>To facilitate our services;</li>
                                    <li>To perform engineering-related services; or</li>
                                    <li>To assist us in analyzing how our services are used.</li>=======
                                </ul>
                                <br/>
                                We want to inform our website users that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. 
                                However, they are obligated not to disclose or use the information for any other purpose.
                            </SectionBody>
                            <Grid item xs={12} style={{borderBottom: `1px solid ${Colors.auxidusBlue}`, width: '100%', paddingTop: '10px'}}/>
                        </Grid>
                    </PolicySection>

                    <PolicySection spacing={3} container>
                        <Grid item xs={12} sm={4}>
                            <SectionTitle variant={'h5'}>
                                Security
                            </SectionTitle>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <SectionBody  >
                            We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, 
                            or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
                            </SectionBody>
                            <Grid item xs={12} style={{borderBottom: `1px solid ${Colors.auxidusBlue}`, width: '100%', paddingTop: '10px'}}/>
                        </Grid>
                    </PolicySection>

                    <PolicySection spacing={3} container>
                        <Grid item xs={12} sm={4}>
                            <SectionTitle variant={'h5'}>
                                Links to Other Sites
                            </SectionTitle>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <SectionBody  >
                                Our website may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. 
                                Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over, and assume no responsibility for the content, privacy policies, or 
                                practices of any third-party sites or services.
                            </SectionBody>
                            <Grid item xs={12} style={{borderBottom: `1px solid ${Colors.auxidusBlue}`, width: '100%', paddingTop: '10px'}}/>
                        </Grid>
                    </PolicySection>

                    <PolicySection spacing={3} container>
                        <Grid item xs={12} sm={4}>
                            <SectionTitle variant={'h5'}>
                                Children's Privacy
                            </SectionTitle>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <SectionBody  >
                                Our services do not address anyone under the age of 13. We do not knowingly collect personal identifiable information from children under 13. In the case we discover that a child under 13 has provided us 
                                with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us 
                                so that we will be able to do necessary actions.
                            </SectionBody>
                            <Grid item xs={12} style={{borderBottom: `1px solid ${Colors.auxidusBlue}`, width: '100%', paddingTop: '10px'}}/>
                        </Grid>
                    </PolicySection>

                    <PolicySection spacing={3} container>
                        <Grid item xs={12} sm={4}>
                            <SectionTitle variant={'h5'}>
                                Changes to This Privacy Policy
                            </SectionTitle>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <SectionBody  >
                            We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. 
                            These changes are effective immediately, after they are posted on this page.
                            </SectionBody>
                            <Grid item xs={12} style={{borderBottom: `1px solid ${Colors.auxidusBlue}`, width: '100%', paddingTop: '10px'}}/>
                        </Grid>
                    </PolicySection>

                    <PolicySection spacing={3} container>
                        <Grid item xs={12} sm={4}>
                            <SectionTitle variant={'h5'}>
                                Contact Us
                            </SectionTitle>
                        </Grid>
                        <Grid item xs={12} sm={8} style={{marginBottom: '50px'}}>
                            <SectionBody  >
                                If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.
                            </SectionBody>
                        </Grid>
                    </PolicySection>

                </Container>
            </PolicyWrapper>
        </Layout>
    )
}


const HeaderText = styled(Typography)`
    line-height: 250px;
    font-size: 60px;
    color: ${Colors.auxidusBlue};
    font-weight: bold;
`;
const HeaderImage = styled.div`
    background: url(${PrivacyImg}) no-repeat center center;
    background-size: contain;
    height: 200px;
    width: auto;
    position: relative;
    margin: auto;
`;
const PolicyHeader = styled.div`
    
`;

const PolicySection = styled(Grid)`
`;
const PolicyWrapper = styled.div`
    margin-top: 50px;
`;
const SectionTitle = styled(Typography)`
    font-weight: bold;
    text-align: right;
    text-transform: uppercase;
    color: ${Colors.auxidusBlue};
    @media only screen and (max-width: 800px) {
        text-align: left;
    }
`;
const SectionBody = styled(Typography)`
    color: gray;
`;